/* Base Rule */
@font-face {
  font-family: "Haurabold", sans-serif;
  src: url("../public/hauora_sans/fonts/otf/Hauora-Bold.otf");
}

@font-face {
  font-family: "Haurasemibold", sans-serif;
  src: url("../public/hauora_sans/fonts/otf/Hauora-SemiBold.otf");
}

@font-face {
  font-family: "Hauramedium", sans-serif;
  src: url("../public/hauora_sans/fonts/otf/Hauora-Medium.otf");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 62.5%;
}

#html {
  font-size: 100%;
}

body {
  margin: 0;
  padding: 0;
  /* min-height: 100vh; */
  background-color: var(--light-bg-color);
  color: var(--light-color);
  font-family: "Hauramedium", sans-serif !important;
}

h1 {
  font-family: "Hauramedium", sans-serif !important;
  font-size: 5.4rem;
  line-height: 1.1;
  font-weight: bold;
}

/* ul {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
} */

li,
input,
textarea {
  font-family: "Hauramedium", sans-serif !important;
  font-size: 1.6rem;
  letter-spacing: 0.05rem;
  word-spacing: 0.06rem;
  border: none;
}

a {
  font-weight: 500;
  font-size: 1.8rem;
  letter-spacing: 0.02rem;
  /* color: var(--header-link-color); */
  text-decoration: inherit;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

ul li {
  list-style: none;
}

thead tr {
  text-align: center !important;
  background-color: var(--table-header-color) !important;
}

th {
  font-size: 1.6rem !important;
}

td {
  font-size: 1.4rem !important;
}

tbody tr td{
  text-align: center !important;
}

option {
  font-size: 1.6rem;
}

header {
  max-width: 100%;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

b {
  color: var(--dark-link-color);
}

.strong_tag_color {
  color: var(--brand-color);
}

main {
  min-height: calc(100% - 8rem);
}

section {
  box-shadow: 0px 2px 4px 1px var(--box-shadow-color);
}

input::placeholder {
  color: var(--placeholder-color);
  font-size: 1.7rem;
}

.css-4ygxeq-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
.css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
  font-size: 1.6rem;
  padding: 1rem !important;
}

/* .css-i4bv87-MuiSvgIcon-root {
  width: 2em !important;
  height: 1.4em !important;
} */

/* WebKit Browsers (Chrome, Safari) */
::-webkit-scrollbar {
  width: 0.8rem;
}

::-webkit-scrollbar-track {
  background: var(--header-navlink-color);
}

::-webkit-scrollbar-thumb {
  background-color: var(--brand-color);
  border-radius: 10rem;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--btnhover-color);

}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root{
  font-size: 1.4rem !important;

}