/* AccessManagement.module.css */
.container {
    width: 100%;
}

.searchBox {
    display: flex;
    justify-content: flex-end;
    gap: 5px;
    margin-bottom: 1rem;
}

.dataGridContainer {
    max-height: 75vh;
    overflow-y: auto;
}

.customHeader {
    background-color: rgb(216, 216, 216);
    color: black;
    font-weight: bold;
}

.customHeader:hover {
    background-color: rgb(200, 200, 200);
}

.searchInput {
    margin-bottom: 1rem;
    width: 400px;
}

.actionButtonContainer {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    padding: 10px;
}

.actionButton {
    border: solid 1px grey;
    background-color: #37668f;
    color: #fff;
    width: 100px;
    border-radius: 5px;
}

.cancelButton {
    background-color: white;
    color: grey;
    width: 100px;
    border: solid 1px grey;
    border-radius: 5px;
}



/* Below css for mui grid table design */
 
.css-n3fyjk-MuiDataGrid-root .MuiDataGrid-container--top [role="row"],.css-mbboce-MuiTableRow-root,.css-1ne8j0w {
    background-color: var(--common-bg-color) !important;
    border-bottom: 1px solid #b9e6fe !important;
    /* margin-bottom: 1.5px !important; */
    font-size: 16px !important;
   
  }
   
   
  .css-n3fyjk-MuiDataGrid-root .MuiDataGrid-row , .css-ooo7gw .MuiDataGrid-row {
    /* color: #98a2b3 !important; */
    color: #1d2939 !important;
    background-color: white;
    font-size: 14px;
    margin-bottom: 2px;
  }
   
  .css-n3fyjk-MuiDataGrid-root .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row:hover,.css-ooo7gw .MuiDataGrid-row:hover{
    font-size: large;
    background-color: white;
    font-size: 15px;
    box-shadow: rgba(0, 0, 0, 0.23) 1px 2px 2px !important;
  }
   
   
  .css-n3fyjk-MuiDataGrid-root ,.css-ooo7gw {
    border-color: #b9e6fe !important ;
    border-radius: 4px !important;
  }
  .css-rqglhn-MuiTable-root,.css-1owb465{
    border:1px solid #b9e6fe !important ;
    border-radius: 4px !important;
  }
   
  .css-rqglhn-MuiTable-root,.css-1owb465{
    border-collapse: separate !important;
  }
   
  .css-13xfq8m-MuiTabPanel-root {
    padding: 0 !important;
  }
   
  .css-hg18dx-MuiButtonBase-root-MuiTab-root,.css-13cp4kv {
    font-size: 1rem !important;
    color: #98a2b3 !important;
  }
   
  .css-hg18dx-MuiButtonBase-root-MuiTab-root.Mui-selected,.css-13cp4kv.Mui-selected {
    color: #026aa2 !important;
    background-color: white !important;
    border-top-left-radius: 10px !important;
    border-top-right-radius: 10px !important;
    box-shadow: 0 2px 4px rgba(0, 0, 0.3, 0.3) !important;
  }
   
  .css-1897kd5 {
    padding: 0 !important;
  }
   
  /* here------------------ */
   
  /* No changes needed for layout.css unless targeting MUI components specifically */
   
  .master-heading {
    color: #37668f !important;
    font-weight: bold !important;
  }
   
  .view-left,
  .view-right,
  .application-status-box {
    padding: 6px 12px !important;
    border-radius: 10px !important;
    background-color: white !important;
  }
  .view-left:hover,
  .view-right:hover,
  .application-status-box:hover {
    box-shadow: rgba(0, 0, 0, 0.23) 0px 4px 4px !important;
  }
   
  .css-yq6j56 {
    padding: 12px;
  }
   
  .css-ooo7gw .MuiDataGrid-container--top [role="row"],
  .css-ooo7gw .MuiDataGrid-container--bottom [role="row"] {
    background-color: var(--common-bg-color) !important;
    border-bottom: 1px solid #b9e6fe !important;
    /* margin-bottom: 1.5px !important; */
    font-size: 16px !important;
  }
   
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
  .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input,
  .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input,
  .ql-editor,.css-qiwgdb,.css-qiwgdb.MuiSelect-select,.css-1x5jdmq,.css-segi59,.css-1x6bjyf-MuiAutocomplete-root,.css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root,.css-z3c6am-MuiFormControl-root-MuiTextField-root,.css-aq7zae-MuiFormControl-root-MuiTextField-root,.css-1ab2xsx,.css-18col2x .MuiOutlinedInput-root,.css-15kq27i {
    background-color: #fcfcfd !important;
    border-color: 1px solid #98a2b3 !important;
  }
   
  .css-1aquho2-MuiTabs-indicator {
    background: none !important;
  }
   
   
   
  .css-i4bv87-MuiSvgIcon-root,.css-vubbuv  {
    height: 20px !important;
    height: 20px !important;
  }
   
  .css-hcdzbm-MuiAutocomplete-root,
  .css-fzem9i-MuiAutocomplete-root {
    padding: 0 !important;
  }
   
  .css-1e6y48t-MuiButtonBase-root-MuiButton-root.Mui-disabled,.css-1ujsas3.Mui-disabled{
    background-color: #98A2B3;
    color: #E4E7EC;
  }
   
   
  .css-ypiqx9-MuiDialogContent-root{
    padding: 0 !important;
  }
   
   
  /* --input  */
   
  .css-hcdzbm-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input{
    padding: 0 !important;
  }
   
  .css-hcdzbm-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall{
    padding: 10px 15px !important;
  }
  
  
  