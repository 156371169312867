.root-main {
  display: flex;
  flex-direction: column;
  height: 100%;
}

#main {
  display: flex;
  flex: 1;
}

.section-main {
  /* max-width: 142rem;
  margin: 0 auto;
  padding: 0rem 2.4rem; */
  margin-top: 7rem;
  margin-left: 6rem;
  overflow-y: auto;
  flex: 1;
  padding: 2.4rem;
}

.vs-logo {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

/* sidebar css start */
.sidebar {
  padding: 2.4rem 1rem;
  background: var(--header-text-color);
  /* color: var(--header-navlink-color); */
  box-shadow: 0px 2px 5px 0px var(--sidebar-box-shadow-color);

  min-height: 100%;
  width: 6rem;
  overflow-y: auto;
  height: 100vh;
  position: fixed;
  z-index: 1;
  top: 7rem;
  bottom: 0;
  left: 0;
  text-align: center;
}

.sidebar.dark {
  background: var(--header-link-color);
  color: var(--header-text-color);
}

.sidebar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  transition: all 0.77s linear;
}

.sidebar-container .sidebarlink {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--header-navlink-color);
}

.sidebaricon svg {
  font-size: 2.4rem;
}

.sidebarlink.active .sidebaricon {
  background-color: var(--brand-color);
  color: var(--header-text-color);
  padding: 0.6rem 0rem;
  border-radius: 0.5rem;
}

.sidebarlink.active .sidebaricon svg {
  font-size: 2.4rem;
}

.sidebarlink.dark.active .sidebaricon {
  background-color: var(--dark-link-color);
  color: var(--header-text-color);
}

.sidebarlink.active .sidebarlink {
  color: var(--brand-color);
  margin-top: 0.4rem;
}

.sidebarlink.active .sidebarlink.dark {
  color: var(--dark-link-color);
}

/* sidebar css end */

/* frontend home page css start */
.section-main:has(.section-home),
.section-main:has(.section-apps) {
  min-height: 90.8vh;
  padding: 2.2rem;
  background-image: url("../assets/homepage.webp");
  background-position: center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}

.section-main:has(.section-home.dark, .section-apps.dark) {
  background-image: url("../assets/darkhomebackground.webp");
}

.section-home.light,
.section-apps.light,
.section-home.dark,
.section-apps.dark {
  background-color: transparent;
}

/* .section-apps.dark {
  background-image: url("../assets/darkhomebackground.webp");
} */

.section-home .grid-two--cols {
  justify-content: center;
  align-items: center;
  gap: 4rem;
  grid-template-columns: 0.5fr 1.3fr;
}

.user-profile {
  margin: auto;
}

.user-profile h1 {
  font-size: 3.2rem;
}

.user-details {
  margin-top: 2.4rem;
  display: flex;
  align-items: center;
}

.user-image--container {
  flex: 0 0 auto;
  width: 10rem;
  height: auto;
  overflow: hidden;
}

.user-image--container img {
  width: 10rem;
  height: 10rem;
  object-fit: contain;
  border-radius: 50%;
}

.user-info {
  margin-left: 1.5rem;
}

.user-name {
  font-size: 2.5rem;
  font-weight: 550;
  line-height: 1.5;
  color: var(--dark-bg-color);
}

.user-email,
.user-email.light {
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.2;
  letter-spacing: 0.10rem;
  color: var(--header-navlink-color);
}

.user-companyname {
  font-size: 2rem;
  font-weight: 500;
  line-height: 1.6;
  letter-spacing: 0.05rem;
  color: var(--dark-bg-color);
}

.user-email.dark {
  color: var(--header-navlink-color);
}

.plans-container {
  position: relative;
}

.plans-container .plans-page {
  padding: 2.2rem 1.2rem;
  border-radius: 2.5rem;
}

.plans-container p {
  font-size: 2.8rem;
  font-weight: 525;
}

.plans-container .tabs-section .tab-container>div {
  padding: 0rem;
  position: relative;
}

.earlybirdImage {
  position: absolute;
  left: -4%;
  top: -5%;
  width: 12%;
  height: auto;
}

.section-plans .earlybirdImage {
  position: absolute;
  top: -6%;
  left: -5%;
  width: 12%;
}

.basic-badge {
  color: var(--brand-color);
}

.standard-badge {
  color: var(--yellow-color);
}

.premium-badge {
  color: var(--pink-color);
}

.plans-section {
  max-width: 92rem;
  display: flex;
  margin: auto;
  gap: 1.2rem;
  text-align: center;
}

.plan {
  margin-top: 3rem;
  background-color: var(--header-text-color);
  position: relative;
}

.plan:nth-child(1) {
  border: 0.1rem solid var(--brand-color);
  border-radius: 4rem;
  flex: 1;
}

.plan:nth-child(2) {
  border: 0.1rem solid var(--brand-color);
  border-radius: 4rem;
  flex: 1;
}

.plan:nth-child(3) {
  border: 0.1rem solid var(--brand-color);
  border-radius: 4rem;
  flex: 1;
}

.blue {
  /* background-color: var(--green-color); */
  border-bottom: 0.1rem solid var(--brand-color);
}

.yellow {
  /* background-color: var(--yellow-color); */
  border-bottom: 0.2rem solid var(--yellow-color);
}

.pink {
  /* background-color: var(--pink-color); */
  border-bottom: 0.2rem solid var(--pink-color);
}

.plan-header {
  padding: 1.6rem;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  position: relative;
}

.plan-name {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 2.2rem;
  font-weight: 550;
  letter-spacing: 0.10rem;
}

.plan-detail .plan-name {
  justify-content: flex-start;
}

.plan-title {
  margin-bottom: 0.3rem;
}

.plan-price {
  margin-top: 0.4rem;
  font-size: 2.6rem;
  font-weight: 550;
  line-height: 1.5;
  letter-spacing: 0.30rem;
  color: var(--brand-color);
}

.plan-price::after {
  content: "/User";
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--dark-card-color);
  letter-spacing: 0.01rem;
}

.plan-prices {
  font-size: 1.3rem;
  font-weight: 500;
  text-decoration: line-through;
  /* color: var(--header-navlink-color); */
  letter-spacing: 0.10rem;
}

.plan-prices::after {
  content: "/User";
  font-size: 1.2rem;
  color: var(--header-navlink-color);
  letter-spacing: 0.01rem;
  padding-bottom: 0.5rem;
}

.free-trail--plan-price {
  margin-top: 0.8rem;
  font-size: 1.6rem;
  font-weight: 550;
  line-height: 1.5;
  letter-spacing: 0.10rem;
}

.free-trail--plan-price.summary {
  margin-top: 0rem;
  font-size: 2.6rem;
}

.free-tails--addon {
  font-size: 1.4rem;
  letter-spacing: 0.05rem;
}

.discount-offer {
  position: absolute;
  top: 2%;
  right: -10%;
  width: 24%;
  height: auto;
  transform-origin: top center;
  animation: swing 2s infinite ease-in-out;
}

@keyframes swing {
  50% {
    transform: rotate(10deg);
  }
}

.plan-body {
  min-height: 42rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: start;
  font-size: 1.6rem;

}

.plan-body :is(.second-container,
  .third-container,
  .fourth-container) {
  padding: 1.2rem;
}

.plan-body .third-container {
  padding: 1.8rem 1.2rem;
}

.plan-body ul {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.plan-body ul li,
.product-feature ul li {
  display: flex;
  gap: 1rem;
}

.plan-body ul li span:first-child,
.product-feature ul li span:first-child {
  font-size: 1.5rem;
  color: var(--brand-color);
  /* border-radius: 50%; */
}

/* .plan-body ul li::before,
.product-feature ul li::before {
  content: "\2713";
  background-color: var(--brand-color);
  border-radius: 50%;
  color: var(--header-text-color);
  font-size: 1.5rem;
  padding: 0.5rem 0.7rem;
  margin: 0rem 1rem 0rem 0rem;
} */

.plan-body .feature {
  font-size: 2rem;
  color: var(--brand-color);
}


.first-container {
  color: var(--brand-color);
  border-bottom: 0.1rem solid var(--input-border-color);
  Padding: 1.2rem 1rem;
  font-size: 1.8rem;
  letter-spacing: 0.1rem;
}

/* .feature-header {
  font-size: 1.2rem;
} */

.features-title {
  font-size: 1.3rem;
  letter-spacing: -0.0rem;
}

.core-feature {
  display: inline-block;
  color: var(--brand-color);
  margin: 1.2rem 0rem;
  font-size: 1.6rem;
  line-height: 1.6;
}

.plan-viewmore {
  display: inline-block;
  /* margin: 2rem 0rem; */
  font-size: 1.6rem;
  font-weight: 500;
  text-transform: uppercase;
  color: var(--brand-color);
  text-decoration: underline;
  cursor: pointer;
}

.commonBtn.plan-btn {
  font-size: 1.6rem;
  padding: 1.1rem 2rem;

}

.commonBtn.purchased {
  background-color: transparent;
  color: var(--brand-color);
}

.plans-container .free-trail {
  margin: 3rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4.8rem;
}

.plans-container .free-trail::after {
  content: "Don't see the perfect fit?  Build your ideal solution with custom plans and add-ons. Contact us at info@vliv.app";
  position: absolute;
  bottom: 1rem;
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.07rem;
}

.free-trail p {
  display: inline-block;
  font-size: 3rem;
  font-weight: 600;
  line-height: 1.6;
}

.free-trail button {
  width: 28%;
}

.faq-section .faq-header {
  text-align: center;
  font-size: 3rem;
  font-weight: 600;
  padding: 0rem 0rem 3rem 0rem
}

.faq-section .grid {
  gap: 1.5rem;
}

.faq-section .faq-question {
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.6;
  color: var(--dark-card-color);
}

.faq-section .faq-question.dark {
  background-color: transparent;
  color: var(--header-text-color);
}

.faq-section .faq-answer {
  max-width: 58rem;
  margin: 1rem 0rem;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.05rem;

}

.faq-section .faq-example {
  max-width: 58rem;
  font-size: 1.4rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.05rem;
  color: var(--dark-card-color);
}

.faq-section .faq-example.dark {
  background-color: transparent;
  color: var(--header-text-color);
}

/* .home-container {} */
.plan-expirenote--container {
  font-size: 2rem;
  background-color: var(--btntext-color);
  color: var(--danger-color);
  padding: 2rem 1rem;
  margin: 0rem 0rem 3rem 0rem;
  border-radius: 1rem;
  box-shadow: 0px 2px 5px 0px var(--sidebar-box-shadow-color);
  display: flex;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.plan-expirenote--container span {
  cursor: pointer;
  color: var(--dark-bg-color);
}

.moving-text {
  /* display: inline-block;
  white-space: nowrap; */
  animation: moveText 10s linear infinite;
}

@keyframes moveText {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(140%);
  }
}

.home-image--container {
  position: relative;
}

.homeImage {
  width: 95%;
  height: auto;
  object-fit: contain;
  opacity: 0.9;
}

.user-details--container {
  position: absolute;
  top: 3rem;
  left: 0rem;
  background: var(--home-image-color);
  color: var(--home-text-color);
  padding: 1rem 3rem 1rem 3rem;
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
}

.greeting-text {
  font-size: 3.75rem;
  font-weight: 600;
}

.username {
  font-size: 2.5rem;
  font-weight: 600;
  margin-top: 0.8rem;
}

.home-container .user-profile {
  margin: 2rem;
}

.plan-details {
  display: flex;
  align-items: center;
  margin-top: 0.7rem;
}

.user-planname {
  font-size: 1.8rem;
  color: var(--btntext-color);
  padding: 0.5rem 2rem;
  border-radius: 5rem;
}

.user-planname.basicplan {
  background-color: var(--brand-color);
  color: var(--btntext-color);
}

.user-planname.standardplan {
  background-color: var(--yellow-color);
  color: var(--btntext-color);
}

.user-planname.premiumplan {
  background-color: var(--pink-color);
  color: var(--btntext-color);
}

.basicplan {
  background-color: var(--brand-color);
}

.standardplan {
  background-color: var(--yellow-color);
}

.premiumplan {
  background-color: var(--pink-color);
}

.user-plan-expirydate {
  font-size: 1.5rem;
}

.user-plan-expirydate.light {
  color: var(--header-navlink-color);
  margin-left: 2rem;
}

/* frontend home page css end */

/* Apps page css start */
.section-apps .common-title {
  text-align: start;
}

.section-apps .grid {
  gap: 1rem;
  margin-right: 3rem;
  margin-top: 1rem;
}

.productcard {
  background-color: var(--header-text-color);
  color: var(--header-link-color);
  border-radius: 2rem;
  padding: 1rem;
}

.product-icon {
  display: inline-block;
  background-color: var(--brand-color);
  color: var(--btntext-color);
  border-radius: 50%;

}

.product-icon svg {
  font-size: 2.2rem;
  margin: 1rem;
}

.product-details {
  display: flex;
  align-items: center;
  gap: 1.5rem;
}

.product-title {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: var(--brand-color);
}

.productcard .cardPara {
  margin: 2rem 0rem 1rem 0rem;
  font-family: "Hauramedium", sans-serif;
  line-height: 1.4;
  letter-spacing: 0.10rem;
}

.product-feature ul {
  padding: 1rem;
}

.prduct-button--container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.productcard .prduct-button--container {
  justify-content: flex-end;
}

/* .prduct-button--container button {
  font-size: 1.8rem;
} */

/* .prduct-button--container .commonBtn.purchased {
  border: 0.15rem solid var(--header-navlink-color);
} */

/* Apps page css end */


.signin-main {
  /* width: 100vw; */
  height: 100vh;
  background-image: url("../assets/signInBackground.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  flex-wrap: wrap;
}

.logo_img {
  width: 30%;
  height: 20%;
}

.signin-left p {
  color: white;
  font-size: 1.8rem;
  margin-top: 2rem;
  text-align: center;
}

/* .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 1.5rem !important;
} */
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root,
.MuiFormLabel-root,
.MuiInputLabel-root,
.MuiInputLabel-formControl,
.MuiInputLabel-animated,
.MuiInputLabel-sizeMedium,
.MuiInputLabel-outlined,
.MuiFormLabel-colorPrimary,
.MuiInputLabel-root,
.MuiInputLabel-formControl,
.MuiInputLabel-animated,
.MuiInputLabel-sizeMedium,
.MuiInputLabel-outlined,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  /* line-height: 1.2 !important; */
  font-size: 1.6rem;
  background-color: var(--btntext-color);
}

.pointer {
  cursor: pointer;
}

/* header css start */
.main-container {
  position: sticky;
  position: fixed;
  height: 7rem;
  width: 100%;
  top: 0;
  z-index: 1000;
  background-color: var(--header-text-color);
}

.section-navbar .header-section {
  padding: 0rem 2.4rem 0rem 1.2rem;
}

.navbaricon svg {
  font-size: 2.2rem;
  color: var(--brand-color);
}

.navbaricon.dark svg {
  color: var(--dark-link-color);
}

#menu-appbar ul[role="menu"] {
  padding: 0.8rem;
}

.header-editIcon {
  position: absolute;
  top: 1rem;
  left: 6rem;
  font-size: 1.8rem;
  color: var(--brand-color);
}

/* header css end */

/* payment status css start */
/* .section-payment {} */

.payment-container {
  min-height: 100%;
  max-width: 50rem;
  margin: auto;
  padding: 2.6rem 2.2rem;
  text-align: center;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  border-radius: 1rem;
}

.payment-icon {
  font-size: 8rem;
  color: var(--payment-sucess);
}

.payment-icon.failure {
  color: var(--payment-failure);
}

.payment-status-heading {
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.6;
}

.payment-details {
  margin: 3rem 0rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.payment-details li {
  display: flex;
  justify-content: space-between;
  font-size: 1.5rem;
}

.payment-details li:last-child {
  margin: 2rem 0rem;
  padding: 0.8rem 0rem;
  border-block: 0.1rem dashed var(--button-border-color);
}

.payment-details li:last-child span:last-child {
  font-size: 2.4rem;
  /* font-weight: 600; */
}

.payment-details li span:first-child {
  color: var(--button-border-color);
}

.payment-details li span:last-child {
  color: var(--header-link-color);
  /* font-weight: 600; */
}

.payment-button {
  max-width: 20rem;
  margin: 1rem auto;
}

.payment-button button:last-child {
  margin-top: 2rem;
}

.payment-failed--terms {
  text-align: center;
  font-size: 1.4rem;
  color: var(--button-border-color);
  line-height: 1.6;
}

.contact-us-link {
  color: var(--brand-color);
  font-size: 1.4rem;
}

.amount {
  padding: 0.5rem 0rem;
}

/* payment status css end */

/* payment invoice css start */
.print-section {
  padding: 0.5rem;
}

.print-heading {
  text-align: end;
  font-size: 2.5rem;
  font-weight: 500;

}

.row-line {
  margin: 1rem 0rem;
  color: var(--payment-row-color);
}

.payment-details--container {
  display: flex;
  justify-content: space-between;
}

.payment-details--container .payment-details {
  gap: 1rem;
}

.payment-details-heading {
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.payment-address--details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.payment-details-para {
  font-size: 1.5rem;
  margin: 0.8rem 0rem;
}

.payment-details--container .payment-details {
  margin: 2rem 0rem;
}

.payment-details--container .payment-details li {
  font-size: 1.8rem;
  line-height: 1.6;
  gap: 4rem;
}

.payment-details--container .payment-details li span:first-child {
  color: var(--dark-bg-color);
  font-weight: 550;
}

.payment-details--container .payment-details li span:last-child {
  color: var(--header-link-color);
}

.payment-details--container .payment-details li:last-child {
  margin: 0rem 0rem;
  padding: 0rem 0rem;
  border-block: none;
}

.payment-details--container .payment-details li:last-child span:last-child {
  font-size: 1.5rem;
  font-weight: 550;
}

.payment-details--container .payment-details li span:last-child {
  color: var(--header-link-color);
}

.payment-amount {
  font-size: 1.6rem;
  margin: 1rem 0rem;
}

.payment-subtotal {
  justify-content: end;
  gap: 15rem;
}

.subtotal-heading--container,
.total-heading--container {
  text-align: end;
}

.amount-container:last-child {
  border-bottom: 0.1rem solid var(--button-border-color);
}

/* payment invoice css end */

/* invite user css start */
.common-table-body tr td {
  padding: 0.5rem;
  text-align: center;
}

.primarycolor {
  color: var(--brand-color);
}

.adduser {
  margin-bottom: 0rem;
}

.css-38raov-MuiButtonBase-root-MuiChip-root {
  font-size: 1.5rem;
}

.modules-access--feild .MuiOutlinedInput-root {
  padding: 1.2rem 1rem;
}

.modules-access--feild .MuiOutlinedInput-root:has(div[tabindex="-1"]) {
  padding: 0.9rem 1rem;
}

/* invite user css end */

/* Settings page css start */
.setting-page .common-heading {
  font-size: 2rem;
  font-weight: 600;
}

.setting-tab {
  display: flex;
  gap: 1.5rem;
}

.setting-tab a {
  font-size: 1.5rem;
  letter-spacing: 0.05rem;
  color: var(--button-border-color);
  padding: 1.2rem 2rem;
  border: 0.1rem solid var(--button-border-color);
  border-radius: 2.4rem;

}

.setting-tab .active {
  background-color: var(--brand-color);
  color: var(--header-text-color);
}

.billingandplan .common-heading {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;
  color: var(--header-link-color);
}

.billing-plan {
  border: 0.1rem solid var(--brand-color);
  padding: 1rem;
  /* display: flex;
  flex-direction: column;
  gap: 2rem; */
  border-radius: 1rem;
}

.billing-plan h2 {
  font-size: 1.8rem;
  line-height: 1.6;
  color: var(--brand-color);
}

.billing-plan .second-container {
  display: flex;
  gap: 3rem;
}

.billing-plan .second-container ul {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.billing-plan .second-container ul li {
  display: flex;
  gap: 1rem;
}

.billing-plan .second-container ul li {
  color: var(--brand-color);
}

.plan-details {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.plan-details p {
  font-size: 1.6rem;
  margin: 1rem 0rem 2rem 0rem;
  letter-spacing: 0.05rem;
}

.plan-subscription {
  color: var(--header-link-color);
  font-weight: 550;
}

.plan-validity {
  color: var(--header-navlink-color);
  font-weight: 500;
}

.plan-details button {
  font-size: 1.8rem;
}

.small-text {
  display: inline-block;
  font-size: 1.3rem;
  margin-top: 0.6rem;
  letter-spacing: 0.08rem;
}

.billing-form .form-section label {
  font-weight: 600;
}

.billing-form .common-heading {
  font-size: 1.5rem;
  font-weight: 500;
  color: var(--header-link-color);
  line-height: 1.6;
  letter-spacing: 0.05rem;
}

.billingAddress textarea {
  resize: none;
  border-radius: 1.5rem;
}

.billingContacts {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-top: -1rem;
}

.emailContainers {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
}

.newemail {
  width: 10rem;
  font-size: 1.6rem;
  padding: 0.5rem 1rem;
  border: 0.1rem solid var(--brand-color);
  cursor: pointer;
  color: var(--brand-color);
  border-radius: 0.8rem;
}

.deleteicon {
  font-size: 2rem;
  color: var(--danger-color);
}

.billing-form .form-section .button-container {
  justify-content: end;
}

.billing-form .form-section .button-container button {
  width: 25%;
  margin: 0rem;
}

.billing-form-header {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
}

.plan-validity.additionuser {
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.usersbtn {
  background: var(--brand-color);
  color: var(--btntext-color);
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  box-shadow: 0rem 0rem 0.1rem 0.1rem var(--sidebar-box-shadow-color)
}

.usersbtn:active {
  background-color: var(--brand-color);
  color: var(--btntext-color);
}

/* Purchase History css start */
.purchase {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchFeild {
  position: relative;
}

.purchase .common-heading {
  font-size: 2.5rem;
}

.search-icon {
  position: absolute;
  top: 1.4rem;
  right: 1rem;
  font-size: 1.6rem;
  color: var(--header-navlink-color);

}

.purchase-table {
  margin-top: 3rem;
}

.commonpagination .css-pdct74-MuiTablePagination-selectLabel {
  font-size: 1.5rem;
}

.commonpagination .MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-i4bv87-MuiSvgIcon-root {
  font-size: 2rem;
}

.css-10by3a5-MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem {
  font-size: 1.2rem;
}

.commonpagination .css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  font-size: 1.3rem;
}

.commonpagination .css-levciy-MuiTablePagination-displayedRows {
  font-size: 1.5rem;
}

.tableheader th {
  text-align: center;
  background-color: var(--table-header-color);
}

/* Purchase History css end */

/* login history css start */

.view-icon {
  font-size: 1.7rem;
  cursor: pointer;
}

.view-page-title {
  font-size: 1.5rem;
  font-weight: 400;
  color: #98a2b3;
}

.view-page-value {
  font-size: 1.5rem;
  font-weight: 400;
}

/* login history css end */




/* Settings page css end */

/* Style for OTPInput container */
.otp-input-container {
  display: flex;
  justify-content: space-between;
}

/* Style for each OTP input box */
.otp-input-container input {
  flex-grow: 1;
  width: 45px !important;
  height: 50px !important;
  text-align: center;
  font-size: 2rem;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px solid #3B6892;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}

.capitalize {
  text-transform: capitalize;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
  font-size: 2.5rem !important;
}


/* To hide text editor water mark */
.jodit-status-bar-link {
  display: none;
}

.css-1km1ehz,
.css-xa1gac,
.css-1x5jdmq,
.css-1sun1vi,
.css-o1spff {
  font-size: 1.6rem !important;
}

/* .css-clttge-MuiAutocomplete-root .MuiOutlinedInput-root {
  padding: 16.5px 14px;
} */

/* plan checkout css start */
.section-home .checkoutHeader {
  background-color: var(--header-text-color);
  border-radius: 1rem;
  padding: 1.5rem 1rem;
  border-bottom: 0.01rem solid var(--sidebar-box-shadow-color);
  /* box-shadow: 0px 0px 5px -1px var(--sidebar-box-shadow-color); */

}

.section-home .checkoutHeader.dark {
  background-color: var(--dark-bg-color);
}

.checkout-order--details {
  display: flex;
  gap: 6.4rem;
  align-items: center;

}

.checkoutOrderId {
  font-size: 3.2rem;
}

.checkoutpayment {
  padding: 1rem 2rem;
  font-size: 2rem;
  letter-spacing: 0.05rem;
  background-color: var(--pending-bg-color);
  color: var(--pending-color);
}

.section-home .plan-checkout {
  gap: 2rem;
  padding: 2rem;
  background-color: var(--header-text-color);
  border-radius: 1rem;
  box-shadow: 0px 0px 5px -1px var(--sidebar-box-shadow-color);
}

.plancheckout-heading {
  color: var(--light-color);
  font-size: 2rem;
  font-weight: 600;
}

.plancheckout-card {
  border: 0.1rem solid var(--brand-color);
  padding: 1rem;
  border-radius: 1rem;
  margin-top: 1rem;
  max-width: 60%;

}

.plancheckout-details {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.6;
  color: var(--brand-color);
}

.plancheckout-type {
  font-size: 2.4rem;
  font-weight: 500;
  color: var(--light-color);
}

.checkoutAddon {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: var(--light-color);
}

.checkout-section {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  /* padding: 2rem; */
  border-radius: 0.5rem;
  background-color: var(--btntext-color);
}

.checkout-section:nth-of-type(2) {
  background-color: var(--header-text-color);
}

.customer-deatils,
.couponcode {
  background-color: var(--btntext-color);
}

.cards-checkout {
  padding: 1rem 2rem;
  border-radius: 1rem;
  /* box-shadow: 0rem 0rem 0.2rem 0.2rem var(--sidebar-box-shadow-color); */
}

.commonBtn:active {
  background-color: var(--brand-color);
  color: var(--btntext-color);
}

.addonpackname li {
  margin: 1.5rem 0rem;
  color: var(--light-color);
}

.checkout-userdetails li {
  display: flex;
  justify-content: space-between;
  margin: 1.5rem 0rem;
  color: var(--light-color);
}

.checkout-userdetails li span:first-child {
  color: var(--header-navlink-color);
}

.couponcode h2 {
  color: var(--dark-link-color);
}

.couponcode-feild {
  display: flex;
  gap: 3rem;
  position: relative;
}

.closeicon {
  position: absolute;
  top: 30%;
  left: 56%;
  font-size: 1.4rem;
  cursor: pointer;
}

.couponcode-feild .form-feild {
  border-radius: 1rem;
  padding: 1rem;
}

.couponcode-feild .commonBtn.purchased {
  width: 50%;
}

.coupon-card--container {
  height: 29rem;
  overflow-y: auto;
  padding: 1rem;
}

.couponcode-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0rem;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 0rem 0rem 0.2rem 0.2rem var(--sidebar-box-shadow-color);

}

.coupon-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-right: 0.2rem dashed var(--payment-row-color);
  width: 25%;
}

.coupon-logo-image {
  width: 35%;
  height: 35%;
}

.coupon-details {
  padding: 1rem;
}

.coupon-details h2 {
  font-size: 2.4rem;
  color: var(--light-color);
  line-height: 1.6;
}

.coupon-details.dark h2 {
  color: var(--dark-color);
}

.coupon-details p {
  font-size: 1.6rem;
  line-height: 1.6;
  color: var(--light-color);
}

.coupon-details span {
  font-size: 1rem;
  color: var(--header-navlink-color);
  line-height: 1.6;
}

.checkout-section .subtotal-heading--container,
.total-heading--container {
  margin: 0rem 0;
}

.checkout-section .calculation-container li span:last-child {
  font-weight: 600;

}

.checkout-section .payment-details-para {
  display: flex;
  justify-content: flex-end;
}

.checkout-section .payment-details-para span {
  display: block;
  width: 40%;
  font-weight: 600;
}

.checkout-section .payment-details-para span:first-child {
  font-weight: 500;
}

.checkout-section .payment-details-para:nth-of-type(2) :last-child {
  font-weight: 600;
}

.total-heading--container .payment-details-para span:first-child {
  font-weight: 600;
}

.checkout-section .payment-details-para:last-child {
  border-bottom: 0.1rem solid var(--header-navlink-color);
}

.checkout-section .total-heading--container .payment-details-para {
  border-bottom: none;

}

.checkout-container {
  display: flex;
  flex-direction: column;
  color: var(--light-color);
}

/* .checkout-container .checkout-button--container div{
  width: 30%;
} */



/* plan checkout css end */