.cursor-pointer {
  cursor: pointer !important;
}

.span-color {
  color: var(--common-color);
}

.heading {
  font-size: 1.8rem;
  font-weight: 400;
}

.loginfields {
  width: 80%;
  margin: 10px 0px;
}

.colored-heading {
  color: var(--header-color) !important;
  font-size: 2rem;
  font-weight: 500;
}

.saveBtn {
  background-color: var(--brand-color);
  padding: 5px 20px;
  color: white;
  font-size: 1.8rem;
  border-radius: 8px;
}

.commonBtn.inviteBtn {
  width: 20%;
  text-transform: capitalize;
  padding: 0.5rem 1.5rem;
  border-radius: 1rem;
  font-size: 1.8rem;
}

.cancelBtn {
  padding: 5px 20px;
  background-color: white;
  color: var(--commonBtn-color);
  font-size: 1.2rem;
}

.formInputs input,
.formInputs .selectInput {
  font-size: 1.2rem;
  padding: 10px 15px !important;
}

.second-nav {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 40px;
  padding: 7px 15px;
  box-shadow: var(--btmbox-shadow);

}

.second-nav-btn {
  color: var(--sec-nav-btn-color);
}

.secondnav-active-btn {
  color: var(--commonBtn-color);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  padding: 3px 10px;
}

.form-label {
  font-family: "Hauramedium", sans-serif !important;
  font-size: 1.8rem;
  font-weight: 500;
}

/* header css start */
.container {
  max-width: 142rem;
  margin: 0 auto;
  padding: 9.6rem 2.4rem;
  /* border: 3px solid red; */
}

.dark {
  background-color: var(--dark-bg-color);
  color: var(--dark-color);
  transition: all 0.3s linear;
}

.light {
  background-color: var(--light-bg-color);
  color: var(--light-color);
  transition: all 0.3s linear;
}

.section-navbar {
  position: sticky;
  top: 0;
  z-index: 10;

}

/* .section-navbar::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 12%;
  border-bottom: 0.5rem solid var(--brand-color);
}  */

.header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.8rem 2.4rem;
  position: relative;
  z-index: 100;

}

.logo-container {
  margin-right: 0rem;
}

.logo-container img {
  height: auto;
}

.nav-container {
  display: flex;
  /* gap: 3rem; */

}

.nav-container a {
  color: var(--header-navlink-color);
  padding: 1rem 2.5rem;
  border-radius: 5rem;
}

.dark.active,
.common-heading.dark,
.common-title.dark,
div[role="button"] svg {
  color: var(--dark-link-color);
  transition: all 0.3s linear;
}

.light.active,
.common-title.light {
  color: var(--brand-color);
}

.button-container {
  display: flex;
  gap: 1.2rem;

}

.modeicon.dark {
  cursor: pointer;
  font-size: 2.5rem;
  color: var(--sun-color);
  margin-top: 0.5rem;
}

.modeicon.light {
  cursor: pointer;
  font-size: 2.4rem;
  color: var(--header-link-color);
  margin-top: 0.9rem;
}

.commonBtn {
  width: 100%;
  border: none;
  color: var(--btntext-color);
  cursor: pointer;
  font-size: 1.6rem;
  font-weight: 500;
  border-radius: 5rem;
  padding: 1rem 2rem;
  background: var(--brand-color);
  font-family: "Haurabold", sans-serif;
  letter-spacing: 0.7px;
  box-shadow: inset 0 0 0 1px var(--header-navlink-color);
  transition: all 0.3s linear;
}

.commonBtnDisable {
  cursor: not-allowed;
  background-color: var(--header-navlink-color);
}

.cancelbtn {
  width: 100%;
  border: 0.3px solid #afafaf;
  color: black;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  border-radius: 5rem;
  padding: 1rem 2rem;
  background: white;
  font-family: "Haurabold", sans-serif;
  letter-spacing: 0.7px;
  transition: all 0.2s linear;
}

.menu-icon {
  display: none;
}

.menu-container {
  background-color: var(--header-link-color);
  position: absolute;
  top: 0;
  right: -2.45%;
  width: 30vw;
  min-height: 100vh;
  transition: all 0.3s linear;
}

.menu-container.dark {
  background-color: var(--light-bg-color);
  transition: all 0.3s linear;
}

.cross-icon {
  font-size: 2.8rem;
  cursor: pointer;
  position: absolute;
  top: 2.5rem;
  left: 2rem;
}

.menu-navbar {
  margin-top: 10rem;
  margin-left: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
}

.menu-item.light {
  background-color: var(--dark-bg-color);
  color: var(--header-navlink-color);
}

.menu-item.light.active {
  background-color: var(--dark-bg-color);
  color: var(--dark-link-color);
}

.menu-item.dark {
  background-color: var(--header-text-color);
  color: var(--header-navlink-color);
}

.menu-item.dark.active {
  color: var(--brand-color);
}

.menu-container.light .cross-icon {
  color: var(--light-bg-color);
}

.menu-container.dark .cross-icon {
  color: var(--dark-bg-color);
}

.home-profile--header button[type="button"] {
  padding: 0rem;
  cursor: pointer;
}

.user-profile--Image {
  object-fit: contain;
  border-radius: 50%;
  border: 0.2rem solid var(--dark-link-color);
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.profilehome button {
  font-size: 2.5rem;
  cursor: pointer;
  padding: 0;
}

.css-8y3x9n {
  font-size: 1rem;
  letter-spacing: 0.05rem;
}

.user-profile-container {
  width: 26rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  font-size: 1.5rem;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  word-break: break-word;
  word-wrap: break-word;
}

/* header css end */

/* hero css start */

.section-hero.light {
  background-image: url("../assets/Hero-background-gradient.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  /* height: 100vh; */
}

.section-hero.dark {
  background-color: var(--dark-bg-color);
  color: var(--dark-color);
}

.hero-container {
  text-align: center;
  color: var(--header-link-color);
  display: flex;
  gap: 3rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5rem 2.4rem;
  transition: all 0.7s linear;
}

.hero-container.dark {
  color: var(--btntext-color);

}

.hero-subheading {
  font-size: 3rem;
  margin: 0rem 0rem 3rem 0rem;
}

.common-heading {
  color: var(--brand-color);
}

.btn-container {
  position: relative;
}

.hero-btn-container {
  width: 100%;
  position: relative;
  z-index: 9;
}

.hero-btn {
  width: 19%;
  padding: 1.4rem 2rem;
  font-size: 2rem;
}

.hero-images--container {
  position: relative;
  bottom: 5rem;
  transition: all 0.4s ease;
}

.hero-img {
  width: 100%;
  height: auto;
}

.hero-cloud--img {
  max-width: 18%;
  height: auto;
  position: absolute;
  left: -2%;
  top: 43%;
  transition: all 0.3s linear;
}

.hero-thread--img {
  width: 8%;
  height: auto;
  position: absolute;
  left: 12%;
  top: 52%;
}

/* hero css end */

/* why vlib css start */
.grid {
  display: grid;
}

.grid-two--cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid-three--cols {
  grid-template-columns: repeat(3, 1fr);
}

.grid-four--cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid-five--cols {
  grid-template-columns: repeat(5, 1fr);
}

.common-para {
  font-size: 2rem;
  padding: 5rem 1rem;
  line-height: 1.4;
  letter-spacing: 0.07rem;
}

.common-para~.hero-btn-container button {
  width: 36%;
  margin: 4rem 0rem 0rem 0rem;
}

.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-player {
  width: 100%;
  height: auto;
  border-radius: 1.2rem;
}

.common-title {
  text-align: center;
  font-size: 3rem;
  font-weight: 500;
  margin-bottom: 2rem;
}

/* why vlib css end */

/* key feature start */

.accordian-container {
  min-height: 50%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  text-align: start;
  padding: 1rem 1rem;
}

.accordian-container>div {
  /* border: 0.10rem solid var(--dark-link-color); */
  box-shadow: none;
}

.accordian-container>div:has(div[aria-expanded="true"]) {
  border-left: 0.45rem solid var(--brand-color);
  box-shadow: 0px 0px 4px 1px var(--box-shadow-color);
}

div[aria-expanded="true"] .pannel-title {
  font-size: 2.2rem;

}

.accordian-container.dark>div:has(div[aria-expanded="true"]) {
  border-left: 0.45rem solid var(--dark-link-color);
}

div[aria-expanded="true"]+div {
  margin-top: -1.5rem;
}

.accordianPannel {
  margin: 0rem 0rem 1rem 0rem;
  border-radius: 1rem;
  transition: all 0.1s linear;
}

.accordian-container .accordianPannel::before {
  height: 0rem;
}

.accordianPannel.dark {
  background-color: var(--dark-card-color);
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root:first-of-type {
  border-radius: 1rem;
}

.pannel-title {
  font-size: 1.8rem;
  font-weight: 600;
}

div[role="button"] svg {
  font-size: 2.5rem;
  font-weight: 600;
}

.pannel-desc {
  font-size: 1.5rem;
}

.css-1f773le-MuiButtonBase-root-MuiAccordionSummary-root {
  box-shadow: 0px 0px 0px 0px var(--light-color);
  /* border-bottom: 0.5px solid var(--light-color); */
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
}

.title {
  font-size: 1.7rem;
  font-weight: 600;
  letter-spacing: 0.05rem;
}

.desc {
  font-size: 1.7rem;
  font-weight: 500;
  letter-spacing: 0.07rem;
}

.key-feature--container,
.section-why--vlib .container {
  gap: 10rem;
}

.section-why--vlib .container,
.section-key--feature .container,
.section-benefits .container,
.section-testimonials .container,
.section-realWorldImpact .container,
.section-blogs .container {
  padding: 2.4rem 2.4rem 4.8rem;
}


.gallery-container {
  gap: 1rem;
  padding: 1rem;
}

.gallery-container img {
  width: 100%;
  /* height: auto; */
  border-radius: 0.7rem;
}

.gallaryimg {
  width: 100%;
  /* height: 100vh; */
}

.first-gallary-container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.first-gallary-container img:first-child {
  border-bottom-right-radius: 4rem;
}

.first-gallary-container img:last-child {
  border-top-right-radius: 4rem;
  min-height: 46%;
}

.second-gallary-container img {
  border-top-left-radius: 4rem;
  border-bottom-left-radius: 4rem;
}

/* key feature end */

/* benefits css start */
.benefits-container,
.testimonial-container {
  gap: 2rem;
}

.efficency-container {
  display: flex;
  border: 0.2rem solid var(--dark-link-color);
  border-radius: 1rem;
  transition: all 0.3s linear;
}

.imgContainer,
.imgContainer>img {
  width: 50%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--card-bg-color);
  border-top-left-radius: 0.8rem;
  border-bottom-left-radius: 0.8rem;
}

.benefits-para--container {
  padding: 0.5rem 1rem 1rem 1.5rem;
}

.efficency-container .benefits-para--container .benefits-title {
  padding: 1rem 1rem 2.5rem 1rem;
}

.benefits-title {
  text-align: center;
  padding: 1rem;
  font-size: 1.85rem;
}

/* key feature end */

/* Testimonials css start */

.testimonial-container .efficency-container .imgContainer {
  width: 30%;
}

.testimonial-container .desc {
  padding: 2rem 2rem;
}

.benefits-para--container>.desc {
  text-align: center;
}

/* Testimonials css end */

/* Real world css start */
.realWorldImpact-container {
  gap: 2.5rem;

}

.realWorldImpactCard {
  text-align: center;
  box-shadow: 0px 0px 4px 1px var(--dark-link-color);
  border-radius: 1rem;
  padding: 1.3rem;
  transition: all 0.3s linear;
}

.cardImage {
  width: 25%;
  height: auto;
  padding: 2.5rem 0rem;
}

.cardPara {
  font-size: 1.8rem;
}

/* Real world css end */

/* Transform business css start */
.section-transform-business {
  padding: 1.5rem 0rem;
}

.transform-business {
  background-color: var(--card-bg-color);
  border-radius: 1rem;
  padding: 1.5rem 2.4rem;
}

.transform-business.dark,
.form-container.dark {
  background-color: var(--dark-link-color);
}

.cards.dark {
  background-color: var(--dark-link-color);
  color: var(--btntext-color);
}

.transform-business h2 {
  padding: 1rem 0rem 4rem 0rem;
  background-color: var(--card-bg-color);
}

.transform-business h3 {
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
}

.transform-business .form-container {
  place-items: center;
}

.form-container {
  gap: 1rem;
  margin: 4rem auto;
}

.form-feild {
  width: 100%;
  border-radius: 3.5rem;
  padding: 1.3rem;
  outline: none;
  padding-left: 2.6rem;
  font-size: 1.6rem;
  background-color: var(--header-text-color);
  border: 0.1rem solid var(--header-navlink-color);
}

.buttonContainer {
  margin: auto;
}

.buttonContainer button {
  padding: 1.5rem 2.4rem;
}

/* Transform business css end */

/* footer css start */
.section-footer .footer {
  padding: 4rem 2.4rem;
}

.info-container {
  /* margin: 0rem 2.5rem; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vliv-footer-logo {
  width: 40%;
  height: auto;
}

.page-container {
  text-align: start;
  cursor: pointer;
}

.page-container ul {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.page-container a {
  font-size: 1.5rem;
  font-weight: 500;
  /* color: var(--header-link-color); */
}



.contactus-heading {
  font-size: 1.7rem;
}

.followus {
  display: inline-block;
  margin: 0.5rem 0rem;
  font-size: 1.5rem;
  font-weight: 600;
}

.image-container {
  margin-bottom: -0.5rem;
}

.image-container ul {
  display: flex;
  gap: 0.8rem;
}

.image-container ul li a span {
  font-size: 2.5rem;
  cursor: pointer;
}

.twitter {
  color: var(--twitter-color);
}

.linkedin {
  color: var(--linked-color);
}

.facebook {
  color: var(--facebook-color);
}

.instagram,
.instagram img {
  margin-top: 0.1rem;
  width: 2.3rem;
  aspect-ratio: 1;
}

.footer-end,
.section-footer {
  box-shadow: 0px 2px 4px 1px var(--box-shadow-color);
}

.footer-end .footer-heading {
  padding: 2.5rem 2.4rem;
}

.footer-heading ul {
  display: flex;
  justify-content: space-between;
}

.footer-heading ul li {
  font-size: 1.35rem;
  font-weight: 500;
  letter-spacing: 0.12rem;
}

/* footer css end */

/* contactus form css start */

.section-contactus .container {
  padding: 2rem 2.4rem;
}

.button-section {
  border: 0.1rem solid var(--button-border-color);
  border-radius: 0.5rem;
  font-size: 2.2rem;
  font-weight: 500;
  text-align: center;
  padding: 0.7rem;
  cursor: pointer;
}

.button-icon {
  padding: 0rem 1rem 0rem 1rem;
}

.contactus-form--container {
  background-image: url("../assets/signInBackground.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 2rem;
  /* height: 100vh; */

}

.contactus-logo--container {
  margin: auto;
  text-align: center;

  & img {
    width: 40%;
    height: auto;
  }

  & h2 {
    font-size: 2.4rem;
    font-weight: 500;
    margin: 3rem 0rem;
    color: var(--btntext-color);
  }
}

.contactus-form {
  width: 80%;
  margin: 3rem auto;
}

.contact-form {
  background-color: var(--header-text-color);
  padding: 2.4rem;
  border-radius: 2.4rem;
}

.contact-form.dark {
  background-color: var(--card-time-color);

}

.contactus-header {
  font-size: 3.2rem;
  font-weight: 700;
}

.contactus-subheading {
  font-size: 2rem;
  font-weight: normal;
  line-height: 1.5;
  margin: 2rem 0rem 4rem 0rem;
}

.form-section {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  /* padding: 0rem 2.5rem; */
  /* margin: 2rem 2rem; */
}

.form-section label {
  display: inline-block;
  margin: 0.5rem 0rem;
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.6;

}

.form-section .button-container button {
  width: 50%;
  margin: 2.5rem auto;
  padding: 1rem 1rem;
  font-size: 1.8rem;
}

.socialmediaContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  & .followus {
    margin-top: 0rem;
    font-size: 1.5rem;
    font-weight: 500;
    color: var(--brand-color);
  }

  & .followus.dark {
    background-color: var(--card-time-color);
    color: var(--header-text-color);
  }
}

.subscription-form {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
}

.subscription-form .buttonContainer {
  margin: 0rem;
}

.text-danger {
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 500;
  letter-spacing: 0.08rem;
  color: var(--danger-color);
  margin: 0.4rem 0rem 0rem 2rem;
}

.text-danger.dark {
  color: var(--dark-danger-color);
}

.select-placeholder {
  color: var(--placeholder-color);
}

.companysizeContainer {
  position: relative;
}

.company-size {
  padding: 1.4rem;
  padding-left: 2.6rem;
  appearance: none;
}

.select-icon {
  position: absolute;
  top: 50%;
  right: 2rem;
  font-size: 2rem;
  color: var(--dark-bg-color);
}

.common-loading {
  color: var(--btntext-color);
}

/* contactus form css end */

/* blog hero section css start */
.section-blog--hero {
  background-image: url("../assets/Hero imgae-Blog1.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  min-height: 48vh;
  /* clip-path: polygon(20% 0%, 80% 0%, 100% 0, 100% 99%, 100% 78%, 50% 92%, 0 77%, 0 0); */
}

.section-blog--hero.dark {
  background-image: url("../assets/Hero imgae-Blog1.webp");
}

.section-blog--hero .container {
  text-align: center;
}

.blog-heading {
  color: var(--dark-color);
  font-size: 4.2rem;
}

.blog-title {
  font-size: 3.5rem;
}

.cursor {
  color: var(--btnhover-color);
}

.blog-subheading {
  font-size: 2rem;
  font-weight: 500;
  margin: 2rem 0rem 3rem 0rem;
  color: var(--dark-color);
}



/* blog hero section css end */

/* blog css start */
/* .section-blogs {
  box-shadow: none;
} */

.section-blogs .container .grid {
  gap: 3rem;
}

.card {
  border-radius: 1rem;
  border: 0.15rem solid var(--dark-link-color);
  cursor: pointer;
  transition: all 0.3s linear;
  overflow: hidden;
}

.cardimg {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 100%;
  height: 25rem;
  object-fit: cover;
}

.card-details {
  padding: 0.5rem 1rem;
}

.card-title-container {
  height: 7rem;
  overflow: hidden;
}

.card-title {
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: 0.05rem;

}

.card-description--container {
  margin: 1.5rem 0rem;
  /* width: 35rem; */
  height: 4.5rem;
  overflow: hidden;
  /* white-space: nowrap; */
  text-overflow: ellipsis;
}

.card-description {
  font-size: 1.4rem;
  letter-spacing: 0.05rem;
  line-height: 1.5;

}

.card-username {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  /* color: var(--card-name-color); */

}

.cardtimeduration {
  display: inline;
  padding: 0.1rem 0.5rem;
  font-size: 0.9rem;
  font-weight: 500;
  border-radius: 1rem;
  /* background-color: var(--card-name-color);
  color: var(--card-time-color); */
}

.card-user--container {
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin: 1.5rem 0rem;
}

.card-time {
  padding: 0.5rem 0rem;
}

.card-user--container {
  font-size: 1.5rem;
}

.usericon {
  margin-right: 0.5rem
}

.username {
  font-weight: 500;
}

.postdate {
  font-size: 1.3rem;
}

/* blog css end */

/* blog-details css start */
.section-blogdetails .container {
  max-width: 76rem;
  padding: 7.6rem 2.4rem;
}

.blog-details--container {
  text-align: center;
}

.blog-posted--user {
  margin: 2.5rem 0rem;
}

.blog-posted--user span {
  font-size: 1.5rem;
}

.blog-details--container img {
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 0.4rem;
}

.desc {
  text-align: start;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 1.6;
  margin-top: 2.5rem;
}

/* blog-details css end */

/* feature css start */
.section-feature--hero .container,
.section-plans .container {
  padding: 5rem 2.4rem;
}

.tabs-section div[aria-label="basic tabs example"] {
  justify-content: center;
  gap: 0rem;
}

.tabs-section div[aria-label="basic tabs example"] button {
  font-size: 1.8rem;
  font-weight: 500;
  font-family: "Hauramedium", sans-serif;
  padding: 1rem 3.5rem;
  background-color: var(--header-text-color);
  border-radius: 10rem;
}

.tabs-section div[aria-label="basic tabs example"] button[aria-selected="true"] {
  background: var(--brand-color);
  color: var(--btntext-color);
  border-radius: 10rem;
}

.tabs-section div[aria-label="basic tabs example"]+span {
  display: none;
}

.tab-container {
  text-align: center;
  font-size: 1.5rem;
}

.feature-heading {
  color: var(--brand-color);
  font-size: 4rem;
  font-weight: 600;
  line-height: 1.6;
}

.feature-heading.dark {
  color: var(--dark-link-color);
}

.common-subheading {
  text-align: center;
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
  color: var(--header-navlink-color);
}

.MuiBox-root.css-19kzrtu .common-subheading {
  margin-top: 2.5rem;
}

.feature-container {
  grid-template-columns: 3fr 1.5fr;
  gap: 2rem;
  margin-top: 5rem;
}

.feature-container .accordian-container.light {
  background-color: var(--header-text-color);
  border-radius: 1rem;
}

.feature-container .accordianDesc .desc,
.key-feature--container .accordianDesc .desc {
  color: var(--header-navlink-color);
}

.feature-Images {
  width: 100%;
  height: 100%;
  padding: 1rem 1rem;
}

.feature-Images img {
  width: 100%;
  height: 100%;
  border-radius: 1rem;
}

/* feature css end */

/* landing page plans css start */

.section-plans .container.plans-container {
  position: relative;
  top: -1rem;
  border-radius: 0rem;
  border: none;
  backdrop-filter: none;
}

.plans-page,
.free-trail {
  max-width: 102rem;
  margin: auto;
  padding: 3.4rem 2.4rem;
  background: linear-gradient(to right,
      rgba(29, 41, 57, 0.05),
      rgba(81, 114, 159, 0.1));
  border: 0.1rem solid rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(1.8rem);
  border-radius: 4rem;
  position: relative;
}

.plans-page.dark {
  color: var(--dark-bg-color);
}

.plans-page::after {
  content: "All prices are Excluding  GST*";
  position: absolute;
  right: 8%;
  margin-top: 0.2rem;
  font-size: 1.4rem;
  line-height: 1.6;
  letter-spacing: 0.05rem;
}

.plans-page.dark::after {
  color: var(--button-border-color);

}

/* landing page plans css end */

/* Terms and Condition css start */
.section-tandc .container,
.section-cancellation .container,
.section-privacypolicy .container,
.section-cookiepolicy .container {
  width: 112rem;
}

.common-paragraph {
  font-family: "Haurasemibold", sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.05rem;
  text-align: start;
  line-height: 1.5;

}

.my-2 {
  margin: 1.5rem 0rem;
}

.bold {
  font-weight: 600;
}

.common-list {
  text-align: start;
}

.common-list li {
  margin: 1rem 0rem;
}

.common-defination {
  font-size: 1.6rem;
}

.common-list ul li {
  list-style: disc;
  font-style: 1rem;
  margin: 0rem 3rem;
  font-size: 1.4rem;
}

.common-list .alphabet-list {
  list-style: lower-alpha;
}

/* Terms and Condition css end */

/* home page modal section start */
.section-modal--container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  background-color: var(--header-text-color);
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  padding: 0.8rem;
  border-radius: 1.5rem;
}

.section-plansummary {
  border-radius: 2rem;
}

.plan-summary {
  background-color: var(--header-text-color);
}

.modal-header {
  height: 5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.1rem solid var(--header-navlink-color);
  padding: 0rem 1rem;
}

.modal-title {
  font-size: 2.5rem;
  font-weight: 500;
  color: var(--brand-color);
}

.crossicon {
  font-size: 2rem;
  font-weight: 500;
  cursor: pointer;
  background-color: var(--header-link-color);
  color: var(--btntext-color);
  border-radius: 50%;
  padding: 0 0.5rem;
}

.modal-body .plans-main--container.dark {
  color: var(--dark-bg-color);
}

.modal-body .plans-main--container .plans-main--section {
  min-height: 50rem;
  max-height: 65rem;
  overflow-y: auto;
  padding: 1rem 6rem 1rem 1rem;
  border-right: 0.1rem solid var(--button-border-color);
}

.plans-main--container {
  display: flex;
}

.plans-main--section .plans-section .plan {
  position: relative;
}

.Basic.selected::before,
.Standard.selected::before,
.Premium.selected::before {
  content: "Selected";
  position: absolute;
  top: -3.5rem;
  left: 35%;
  padding: 0.9rem 1rem 0.5rem 1rem;
  font-size: 1.8rem;
  text-align: center;
  color: var(--header-text-color);
  border-top-left-radius: 0.7rem;
  border-top-right-radius: 0.7rem;
}

.Basic.selected::before {
  background-color: var(--brand-color);
}

.Standard.selected::before {
  background-color: var(--yellow-color);
}

.Premium.selected::before {
  background-color: var(--pink-color);
}

.Basic {
  border: 0.1rem solid var(--brand-color);
}

.Standard {
  border: 0.1rem solid var(--yellow-color);
}

.Premium {
  border: 0.1rem solid var(--pink-color);
}

.modal-body .plan {
  cursor: pointer;
}

.additional-user {
  color: var(--header-navlink-color);
}

.add-user {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.addonBtn {
  color: var(--brand-color);
}

.slider-container {
  margin-left: 1rem;
  flex-grow: 1;
}

.notallowed {
  cursor: not-allowed;
}

.addon-section {
  display: flex;
  align-items: center;
  gap: 1rem;
  font-size: 2rem;
  margin-bottom: 1.5rem;
}

.plans-main--section .discount-offer {
  top: 1.1%;
}

.plus-icon {
  font-size: 2.3rem;
  padding-top: 0.5rem;
  color: var(--brand-color);
}

.addon-pack--container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.addon-pack .nocursor {
  cursor: not-allowed;
}

.addon-pack {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
}

.addon-pack label {
  font-size: 1.2rem;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: var(--header-navlink-color);
}

.addon-pack label:has(input:checked) {
  color: var(--brand-color);
}

.custom-checkbox {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
  vertical-align: middle;
  cursor: pointer;
}

.custom-checkbox input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.custom-checkbox .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: var(--header-text-color);
  border-radius: 0.4rem;
  border: 0.1rem solid var(--header-navlink-color);
}

.custom-checkbox input:checked+.checkmark {
  border: 0.1rem solid var(--brand-color);
}

.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.custom-checkbox input:checked+.checkmark:after {
  display: block;
}

.custom-checkbox .checkmark:after {
  left: 5px;
  top: 0px;
  width: 4px;
  height: 10px;
  border: 1rem solid var(--brand-color);
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.addon-pack:has(input:checked) {
  color: var(--brand-color);
}

.calculation-section {
  margin: 3rem auto;
  width: 25%;
}

.fs {
  font-size: 1.8rem;
}

.calculation-title {
  font-size: 2rem;
  font-weight: 500;
}

.plan-detail {
  border: 0.1rem solid var(--header-navlink-color);
  margin: 1rem 0rem;
  border-radius: 0.5rem;
  padding: 0.8rem;
  cursor: pointer;
}

.current-plan {
  box-shadow: 0px 1px 2px 1px var(--header-link-color);
}

.plan-detail .plan-name {
  font-size: 2rem;
  line-height: 1.6;
  position: relative;
}

.plan-detail .plan-price {
  font-size: 3.2rem;
}

.message {
  font-size: 1.4rem;
  letter-spacing: 0.10rem;
  color: var(--header-navlink-color);
  font-family: "Hauramedium", sans-serif;
}

.calculation-container {
  margin: 2rem 0rem;
}

.calculation-container {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.calculation-container li,
.calculation-grandtotal--container li {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
}

.calculation-container li span:first-child {
  font-size: 1.6rem;
  font-weight: 500;
}

.calculation-container li:last-child {
  border-bottom: 0.1rem solid var(--header-navlink-color);
}

.tandc {
  margin: 5rem 0;
  font-size: 1.8rem;
}

.tandc input {
  margin-right: 1rem;
  transform: scale(1.3);
  transform-origin: center;
}

.tandc a {
  color: var(--brand-color);

}

.checkout-button--container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 7rem;
}

.planexpiredate {
  font-size: 2rem;
  font-weight: 600;
  border: 0.2rem solid var(--brand-color);
  border-radius: 0.5rem;
  padding: 0.8rem 2rem;

  & span {
    color: var(--brand-color);
  }
}

.planexpiredate::after {
  content: "days left";
  font-size: 1.3rem;
  margin-left: 0.5rem;
}

/* home page modal section end */

/* Partnership css start */
.section-partnership--hero {
  background: linear-gradient(to top,
      rgba(28, 65, 103, 1) 0%,
      rgba(55, 102, 143, 1) 85%,
      rgba(244, 250, 255, 1) 150%);

}

.section-partnership--hero .container {
  color: var(--btntext-color);
  gap: 2.5rem;
}

.section-partnership--hero .container h3 {
  font-size: 2rem;
  font-weight: 500;
  letter-spacing: 0.05rem;
}

.section-partnership--hero .buttonContainer button {
  background-color: var(--header-text-color);
  color: var(--brand-color);
  font-size: 2rem;
}

.affiliate-buttonContainer span {
  background: linear-gradient(to top, rgba(28, 65, 103, 1) 0%, rgba(55, 102, 143, 1) 85%, rgba(244, 250, 255, 1) 150%);
  font-size: 4.8rem;
  font-weight: 700;
  padding: 1.2rem 10rem;
  border-radius: 2.4rem;
  /* cursor: ; */
}

.affiliate-desc {
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 1.6;
}

.section-affiliate--program .common-heading,
.section-implement--program .common-heading {
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.6;
}

.green {
  background: linear-gradient(45deg, rgba(41, 173, 178, 1), rgba(18, 74, 76, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
  border-image: linear-gradient(45deg, rgba(41, 173, 178, 1), rgba(18, 74, 76, 1));
  border-image-slice: 1;
  border: 0.2rem solid rgba(41, 173, 178, 1);
}

.gold {
  background: linear-gradient(to bottom,
      rgba(249, 242, 149, 1),
      rgba(224, 170, 62, 1),
      rgba(224, 170, 62, 0.67),
      rgba(184, 138, 68, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  border-image: linear-gradient(to bottom,
      rgba(249, 242, 149, 1),
      rgba(224, 170, 62, 1),
      rgba(224, 170, 62, 0.67),
      rgba(184, 138, 68, 1));
  border-image-slice: 1;
  border: 0.2rem solid rgba(224, 170, 62, 1);
}

.pink {
  background: linear-gradient(to bottom,
      rgba(158, 119, 237, 1),
      rgba(90, 68, 135, 1));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* border: 0.2rem solid transparent; */
  border-image: linear-gradient(to bottom,
      rgba(158, 119, 237, 1),
      rgba(90, 68, 135, 1));
  border-image-slice: 1;
  border: 0.2rem solid rgba(158, 119, 237, 1);
}

.affiliate-cards--container,
.why-partnerwithus--card {
  gap: 2.4rem;
}

.affiliate-card {
  max-width: 34.2rem;
  border-radius: 2.4rem;
  padding: 4.8rem 1.2rem 10rem 1.2rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
}

.cardname {
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.6;
  border-bottom: none;
  border: none;
}

.cardimage {
  width: 6vw;
  height: auto;
  margin: 0 auto;
}

.affiliate-title {
  font-size: 2rem;
  font-weight: 500;
  -webkit-text-fill-color: var(--dark-bg-color);
  letter-spacing: 0.05rem;
  height: 4rem;
}

.affiliate-title.dark {
  -webkit-text-fill-color: var(--btntext-color);
}

.affiliate-desc {
  font-size: 1.8rem;
  font-weight: normal;
  line-height: 1.5;
}

.affiliate-card .affiliate-desc {
  -webkit-text-fill-color: var(--dark-bg-color);
}

.affiliate-card .affiliate-desc.dark {
  -webkit-text-fill-color: var(--btntext-color);
}

.section-implement--program.light {
  background-color: var(--header-text-color);
}

.partnerwithus-card {
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
}

.partnerwithus-card p {
  border-radius: 1.2rem;
  padding: 1.2rem;
  font-size: 2rem;
  font-weight: 550;
  background-color: var(--header-text-color);
  height: 8rem;
  display: flex;
  flex-direction: column;
  gap: 2.4rem;
  justify-content: center;
}

.affiliate-implementation-program .affiliate-desc.light {
  font-size: 2rem;
  background-color: var(--header-text-color);
}

.section-affiliate-contactus .container {
  display: flex;
  gap: 2.4rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7rem 2.4rem;
  text-align: center;
}

.section-affiliate-contactus .common-heading {
  font-size: 4.8rem;
  font-weight: 700;
}

.affiliate-contactus-text {
  font-size: 2.4rem;
  font-weight: 500;
}

.affiliate-contactus-text .emaillink {
  font-size: 2.4rem;
  font-weight: 500;
  color: var(--brand-color);
}

.affiliate-contactus-text .emaillink.dark {
  color: var(--dark-link-color);
}

.country-feild {
  font-size: 1.6rem;
}

.country-feild .css-13cymwt-control {
  background-color: var(--header-text-color);
  border: 0.1rem solid var(--header-navlink-color);
  border-radius: 3.5rem;
  padding: 0.5rem;
}


/* Partnership css end */

/* Chatbot css start */
.chatbot-page {
  position: fixed;
  right: 2rem;
  bottom: 6.5rem;
  z-index: 100;
}

.chatBot-icon {
  display: flex;
  background-color: var(--btntext-color);
  border-radius: 50%;
  font-size: 4rem;
  color: var(--brand-color);
  padding: 0.5rem;
  cursor: pointer;
  z-index: -1;
  position: fixed;
  bottom: 1rem;
  right: 2rem;
  box-shadow: 0rem 0rem 12px 1px #6b6b6b;
  transition: transform 0.5s linear;
}

.chatBot-icon.open {
  transform: rotate(180deg);
}

.chatBot-icon.closed {
  transform: rotate(0deg);
}

.chatbot-container {
  /* position: fixed;
  right: 0rem; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  max-width: 38rem;
  margin: auto;
}

.chatbot-container header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  padding: 1.2rem 2.4rem;
  border-radius: 2.4rem;
  background-color: var(--btntext-color);
  box-shadow: 0rem 0rem 5px 0px #6b6b6b;
}

.brandlogo-image {
  width: 30%;
  height: auto;
}

.boticon {
  display: flex;
  align-items: center;
  font-size: 2rem;
  background: var(--robot-chatbot-icon-color);
  color: var(--btntext-color);
  padding: 1rem;
  border-radius: 50%;
}

.chatbot-inbox {
  background-color: var(--dark-bg-color);
  color: var(--input-color);
  border-radius: 2rem;
  box-shadow: 0rem 0rem 7px 0px #6b6b6b;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* padding: 1rem 1rem; */
}

.chatbot-messagebox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 48rem;
  overflow-y: auto;
  padding: 1rem;
}

.chat {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  background-color: var(--robot-message-color);
  padding: 1rem 1rem 1rem 1rem;
  border-radius: 2rem;
}

.message-text {
  font-size: 1.4rem;
  word-spacing: 0.10rem;
  line-height: 1.5;

}

.botmessage {
  font-size: 2rem;
  align-self: flex-start;
  background: var(--robot-chatbot-icon-color);
  padding: 1rem;
  border-radius: 50%;
  display: flex;
}

.chat-outgoing .chat {
  background-color: var(--user-message-color);
}

.usericon {
  font-size: 2rem;
  align-self: flex-end;
  background: var(--user-color);
  padding: 1rem;
  border-radius: 50%;
  display: flex;
}

.chatbox-message--container {
  /* justify-content: center;
  align-items: center; */
  width: 100%;
  padding: 1rem;
  position: relative;

}

.chatbox-message--container>div {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  border: 0.1rem solid var(--button-border-color);
  border-radius: 2.4rem;
}

.text-feild {
  background-color: var(--dark-bg-color);
  color: var(--header-text-color);
  resize: none;
  width: 100%;
  padding: 1rem 1rem;
  outline: none;
  margin: 0 0 0 2rem;
}

.text-feild::placeholder {
  margin: auto;
}

.send-icon {
  font-size: 2rem;
  cursor: pointer;
  color: var(--btntext-color);
  margin-right: 1rem;
}

.chat:has(.typing-indicator) {
  justify-content: flex-start;
  align-items: center;
}

.typing-indicator {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 16px;
}

.typing-indicator span {
  width: 8px;
  height: 8px;
  margin: 0 2px;
  background-color: var(--header-text-color);
  border-radius: 50%;
  display: inline-block;
  animation: typing 1.2s infinite ease-in-out;
}

.typing-indicator span:nth-child(1) {
  animation-delay: -0.32s;
}

.typing-indicator span:nth-child(2) {
  animation-delay: -0.16s;
}

@keyframes typing {

  0%,
  80%,
  100% {
    transform: scale(0);
  }

  40% {
    transform: scale(1);
  }
}

@keyframes loading {
  from {
    opacity: 1;
  }

  to {
    opacity: 0.3;
  }
}

.smallLoader img {
  width: 3rem;
  height: 3rem;
}

/* Chatbot css end */

/* Loading css start */
.loader {
  width: 2rem;
  height: 2rem;
  margin: auto;
  border: 0.3rem solid var(--header-text-color);
  border-top: 0.3rem solid var(--linked-color);
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading-animation {
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

/* .loading-animation img{
 width: 50%;
} */

.loading-animation span {
  font-size: 3.6rem;
  font-weight: 600;
  color: var(--header-navlink-color)
}

/* Loading css end */



/* media query css start */
@media (width <=1560px) {
  .user-profile {
    margin: 0 auto;
  }

  .user-profile h1 {
    font-size: 2.5rem;
  }
}

@media (width <=1400px) {
  html {
    font-size: 56.25%;
    /* 1rem = 9px */
  }

  .header-container {
    padding: 1rem 2.4rem;
  }

  .transform-business {
    padding: 1.5rem 2.4rem;
  }

  .plan-body ul li {
    font-size: 1.3rem;
  }



  .key-feature--container,
  .section-why--vlib .container {
    gap: 5rem;
  }

  .gallery-container img {
    height: 50%;
  }

  .second-gallary-container img {
    height: 100%;
  }

  .blog-heading {
    font-size: 3.8rem;
  }
}

@media (width <=1220px) {
  html {
    font-size: 50%;
    /* 1rem = 8px */
  }

  .logo-container img {
    width: 7vw;
  }

  .header-container {
    padding: 0.5rem 2.4rem;
  }

  .section-why--vlib .grid {
    text-align: center;
  }

  .transform-business {
    padding: 1.5rem 2.4rem;
  }

  .common-para~.hero-btn-container button {
    width: 45%;
    margin: 2rem 0rem 0rem 0rem;
  }

  .hero-btn {
    width: 23%;
    padding: 1.4rem 2rem;
    font-size: 2rem;
  }

  .tabs-section div[aria-label="basic tabs example"] button {
    padding: 2rem 2.2rem;
  }

  .contactus-form {
    margin: 0rem auto;
  }

  .blog-heading {
    font-size: 3.6rem;
  }

  .grid-three--cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .cardimg {
    height: 28rem;
  }

  .card-description--container {
    height: 7.5rem;
  }

  .card-description {
    font-size: 1.7rem;
  }

  .section-plans .earlybirdImage {
    top: -1%;
    left: -2%;
  }

  .css-dg836a-MuiToolbar-root {
    min-height: 45px;
  }

}

@media (width <=980px) {
  .grid-two--cols {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  }

  .grid-three--cols {
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
  }

  .section-home .grid-two--cols {
    grid-template-columns: repeat(1, 1fr);
  }

  .plans-main--container {
    flex-direction: column;
  }

  .key-feature--container .gallery-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .plans-section {
    flex-direction: column;
  }

  .plan-body {
    min-height: 35vh;
  }

  .plan-body ul li {
    font-size: 1.8rem;
  }

  .blog-heading {
    font-size: 2.8rem;
  }

  .blog-subheading {
    font-size: 1.8rem;
    margin: 2rem 0rem 4rem 0rem;
  }

  .section-blog--hero {
    min-height: 66vh;
  }

  .menu-container {
    right: 0%;
  }

  .plans-section {
    max-width: 39rem;
  }

  .contactus-logo--container {


    & img {
      width: 20%;
      height: auto;
    }

    & h2 {
      font-size: 2rem;
      font-weight: 500;
      margin: 3rem 0rem;
      color: var(--btntext-color);
    }
  }

  .nav-container,
  .button-container {
    display: none;
  }

  .menu-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3rem;
    transition: all 0.3s linear;
  }

  .section-plans .earlybirdImage {
    top: -2%;
    left: -5%;
  }

  .billing-form .form-section .button-container {
    display: flex;
  }

}

@media (width <=860px) {
  html {
    font-size: 43.75%;
  }

  .container {
    padding: 5.6rem 2.4rem;
  }

  .header-container {
    padding: 0.5rem 2.4rem;
  }

  .section-blogs .grid-three--cols {
    grid-template-columns: repeat(1, 1fr);
  }

  .transform-business {
    padding: 1.5rem 2.4rem;
  }

  .section-blog--hero {
    min-height: 52vh;
  }

  .card-title {
    font-size: 2.5rem;
  }


}

@media (width <=768px) {
  html {
    font-size: 40%;
  }

  .container {
    padding: 3.6rem 2.4rem;
  }

  .header-container {
    padding: 0.5rem 2.4rem;
  }

  .hero-heading {
    font-size: 4rem;
  }

  .grid-three--cols {
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    justify-content: center;
  }

  .grid-four--cols {
    grid-template-columns: repeat(3, 1fr);
  }

  .common-para~.hero-btn-container button {
    width: 30%;
    margin: 2rem 0rem 0rem 0rem;
  }

  .hero-btn {
    width: 30%;
    padding: 1.4rem 0rem;
    font-size: 1.5rem;
  }

  .section-blog--hero {
    min-height: 59vh;
  }

  .footer-heading ul li {
    font-size: 1rem;
  }

  .signin-main {
    flex-direction: column;
    gap: 5rem;
    flex-wrap: nowrap;
  }

  .signin-right {
    width: 90%;
  }

  .signin-left p {
    font-size: 3rem;
    margin-top: 3rem;
  }

  .tabs-section div[aria-label="basic tabs example"] button {
    font-size: 2.3rem;
  }

  .plans-container .free-trail::after {
    font-size: 1.4rem;
  }

  .logo-container {
    margin-right: 0rem;
  }

}

@media (width <=692px) {
  .hero-heading {
    font-size: 3rem;
  }

  .hero-btn {
    width: 35%;
  }

  .common-para~.hero-btn-container button {
    width: 35%;
  }

  .grid-two--cols {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-four--cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .grid-five--cols {
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
  }

  .footer .fourth-container {
    display: none;
  }

  /* .section-blog--hero {
    height: 20vh;
  } */

  .affiliate-buttonContainer span {
    font-size: 2.8rem;
    padding: 1.2rem 5rem;
    border-radius: 2.4rem;
  }

  /* .css-wv586p-MuiToolbar-root {
    min-height: 45px;
  } */
}

@media (width <=576px) {

  .logo-container img {
    width: 16vw;
  }

  .desc {
    font-size: 2.2rem;
  }

  .title {
    font-size: 2rem;
  }

  /* .info-container {
    display: flex;
  } */

  .vliv-footer-logo {
    width: 32%;
  }

  .page-container {
    margin-top: 0rem;
  }

  .section-blog--hero {
    min-height: 27vh;
  }

  .footer-end .footer-heading {
    padding: 1.5rem 1.4rem;
  }

  .footer-heading ul li {
    font-size: 0.6rem;
  }

  .grid-five--cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .plans-container .free-trail {
    flex-direction: column;
    gap: 1rem;
  }

  .section-plans .earlybirdImage {
    top: 0%;
    left: -4%;
  }

  .free-trail p {
    font-size: 2.5rem;
    font-weight: 600;
    line-height: 1.6;
  }

  .plans-container .free-trail::after {
    font-size: 1.3rem;
  }


}

@media (width <=412px) {

  .hero-btn {
    width: 40%;
  }

  .grid-four--cols {
    grid-template-columns: repeat(1, 1fr);
  }

  .grid-five--cols {
    grid-template-columns: repeat(2, 1fr);
  }

  .plans-container .free-trail::after {
    font-size: 0.9rem;
  }

  .affiliate-buttonContainer span {
    font-size: 2.2rem;
    padding: 1.2rem 3rem;
    border-radius: 2.4rem;
  }

}

.text-transform-cap {
  text-transform: capitalize;
}




/* media query css end */