/* State Rule for CSS */
.saveBtn:hover {
    background-color: var(--common-color);
}

.commonBtn:hover {
    background: var(--btnhover-color);
}

.commonBtnDisable:hover {
    background: var(--dark-card-color);
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root:hover,
.efficency-container:hover,
.commonBtn.light:hover {
    box-shadow: 0px 5px 7px -4px var(--dark-link-color);
}

.css-1086bdv-MuiPaper-root-MuiAccordion-root.dark:hover,
.efficency-container:hover,
.commonBtn.dark:hover {
    box-shadow: 0px 5px 7px -4px var(--dark-link-color);
}

.realWorldImpactCard:hover,
.efficency-container:hover,
.hero-cloud--img:hover {
    transform: scale(1.04);
}

.page-container ul li a:hover {
    color: var(--brand-color);
}

.card:hover {
    box-shadow: 0px 1px 2px 1px var(--dark-link-color);
    transform: scale(1.04);
}

.page-container a:hover {
    text-decoration: underline;
}

.nav-container .navlink:hover {
    background-color: var(--transparent-color);
}

.nav-container .navlink.dark:hover {
    background-color: var(--dark-card-color);
}

.user-profile--Image:hover {
    box-shadow: 0px 0px 4px 1px var(--box-shadow-color);
}

.commonBtn.purchased:hover {
    box-shadow: 0rem 0rem 0.2rem 0.2rem var(--sidebar-box-shadow-color)
}

.cards-checkout:hover, .partnerwithus-card p:hover{
    box-shadow: 0rem 0rem 1rem 0rem var(--sidebar-box-shadow-color);
}

.affiliate-card:hover{
    box-shadow: 0px 0px 4px 1px var(--box-shadow-color);
}