.jodit-wysiwyg ul {
  list-style: disc inside;
  margin-left: 20px;
}

.jodit-wysiwyg ul li {
  list-style: disc inside;
}

.jodit-wysiwyg ol {
  list-style: decimal inside;
  margin-left: 20px;
}

.jodit-wysiwyg ol li {
  list-style: decimal inside;
}

.jodit-wysiwyg strong,
.jodit-wysiwyg b {
  font-weight: bold;
  color: black;
}

/* for tags */
.strong {
  font-weight: bold;
  color: black;
}

.b {
  font-weight: bold;
  color: black;
}

.ol li {
  list-style: decimal inside;
}

.ol {
  list-style: decimal inside;
  margin-left: 20px;
}

.ul li {
  list-style: disc inside;
}

.ul {
  list-style: disc inside;
  margin-left: 20px;
}

/* Add specificity to avoid global styles */
#view-blog-container ul {
  list-style: disc inside;
  margin-left: 20px;
}

#view-blog-container ul li {
  list-style: disc inside;
}

#view-blog-container ol {
  list-style: decimal inside;
  margin-left: 20px;
}

#view-blog-container ol li {
  list-style: decimal inside;
}

#view-blog-container strong,
#view-blog-container b {
  font-weight: bold;
  color: black;
}


