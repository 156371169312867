/* AddBlog.module.css */
.container {
    width: 100%;
  }
  
  .box {
    margin-top: 1rem;
    max-height: 75vh;
    overflow-y: auto;
  }
  
  .uploadBox {
    margin-bottom: 1rem;
    width: 100%;
    background: #fff;
    padding: 2rem;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
  }
  
  .uploadArea {
    border: dotted 2px;
    width: 100%;
    height: 30vh;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .uploadText {
    text-align: center;
    font-weight: bold;
  }
  
  .uploadIcon {
    width: 50px;
    height: 50px;
  }
  
  .inputField {
    margin-bottom: 2rem;
    width: 100%;
    margin-top: 1rem;
    background: #fff;
  }
  
  .descriptionBox {
    margin-bottom: 1rem;
    width: 100%;
    font-size: 1.5rem;
  }
  
  .buttonContainer {
    display: flex;
    gap: 5px;
    justify-content: flex-end;
    padding: 10px;
  }
  
  .cancelButton {
    background-color: white;
    color: grey;
    width: 100px;
    border: solid 1px grey;
    border-radius: 5px;
  }
  
  .submitButton {
    border: solid 1px grey;
    background-color: #37668f;
    color: #fff;
    width: 100px;
    border-radius: 5px;
  }
  

  